<style >
.serviceRemark_reconcilliation {
  /* width: 120px; */
  /* height: 50px; */
  padding: 5px;
  border: 1px solid #5cadff;
  border-radius: 3px;
  color: #808695;
  font-weight: 400;
  position: fixed;
  margin-top: -70px;
  background-color: papayawhip;
  z-index: 999;
}
</style>

<template>
    <!-- <div>
        <div class="workplatform-title p-b-5">基本信息</div>
        <Row class="p-b-5">
            <i-col span="8"><span class="title">代理商 </span>{{contract.companyName}}</i-col>
            <i-col span="8"><span class="title">编号 </span>
              <a @click="modalContract=true">{{contract.code}}</a>
            </i-col>
            <i-col span="8"><span class="title">客户 </span>{{contract.advertiserResultBean?contract.advertiserResultBean.name:''}}</i-col>
        </Row>
        <Row class="p-b-5">
            <i-col span="8"><span class="title">合同名称 </span>{{contract.contractName}}</i-col>
            <i-col span="8"><span class="title">合同类型 </span>{{contract.typeName}}</i-col>
            <i-col span="8"><span class="title">档期时间 </span>{{contract.startDate}} 至 {{contract.endDate}}</i-col>
        </Row>
        <Row class="p-b-5">
            <i-col span="8"><span class="title">创建者 </span>{{contract.userName}}</i-col>
            <i-col span="8"><span class="title">创建时间 </span>{{contract.createTime}}</i-col>
            <i-col span="8"><span class="title">对账状态 </span>
              <tag v-if="executeStatus==='1'">已对账</tag>
              <tag v-else-if="executeStatus==='01'" color="#a59426">部分对账</tag>
              <tag v-else color="#3B3B3B">未对账</tag>
            </i-col>
        </Row>

        <div class="workplatform-title m-t-20 p-b-5">
            对账信息（实际执行）
            <span v-if="exeFlag===0" class="title">
               PS：交易对账操作仅可以完成 <span class="text-orange">上月(含)以前</span> 的数据对账！
            </span>
            <Button v-else-if="transactionReconciliationQuery.searchType===1&&executeStatus!=='1' && isAuth('reconciliation_transaction_confirm')" type="success" size="small" @click="handleConfirmRecondiciliation">确认对账</Button>
        </div>
        <Row class="table-title p-l-5">
            <i-col span="3">资源名称</i-col>
            <i-col span="3">执行档期</i-col>
            <i-col span="3">数量</i-col>
            <i-col span="3">媒介使用费</i-col>
            <i-col span="3">制作费</i-col>
            <i-col span="3">上刊费</i-col>
            <i-col span="3">小计</i-col>
            <i-col span="3">状态</i-col>
        </Row>

        <Row v-for="(item,index) in executeData" :key="index" class= " p-l-5 p-t-5">
            <i-col span="3">{{item.goodName}}</i-col>
            <i-col span="3">{{ formatSchedule(item.startDate, item.endDate)}}</i-col>
            <i-col span="3">{{item.quantity}} {{item.productUnit}}</i-col>
            <i-col span="3">{{formatMoney(item.signUsePrice)}}</i-col>
            <i-col span="3">{{formatMoney(item.producePrice)}}</i-col>
            <i-col span="3">{{formatMoney(item.installPrice)}}</i-col>
            <i-col span="3">{{formatMoney(item.signUsePrice+item.producePrice+item.installPrice)}}</i-col>
            <i-col span="3">
              <tag v-if="item.executeStatus===1">已对账</tag>
              <tag v-else color="#3B3B3B">未对账</tag>
            </i-col>
            <Divider style="margin: 5px 0 5px 0"/>
        </Row>
        <Row class="p-l-5">
            <i-col span="3">合计</i-col>
            <i-col span="3">-</i-col>
            <i-col span="3">-</i-col>
            <i-col span="3" class="text-orange">{{formatMoney(executeSummary.sigUseTotal)}}</i-col>
            <i-col span="3" class="text-orange">{{formatMoney(executeSummary.produceTotal)}}</i-col>
            <i-col span="3" class="text-orange">{{formatMoney(executeSummary.installTotal)}}</i-col>
            <i-col span="3" class="text-orange">{{formatMoney(executeSummary.sigUseTotal+executeSummary.produceTotal+executeSummary.installTotal)}}</i-col>
        </Row>

        <div class="workplatform-title m-t-20">原合同信息</div>
        <i-table
         stripe
          :data="products"
          :columns="productColumns"
          :summary-method="handleSummary"
          show-summary
        ></i-table>
        <div class="workplatform-title m-t-20">
          原合同订购详情
          ( <span class="title">当前合同共计包含<span style="color:#ee7c4b;">{{ orderCount }}</span>个订单</span>)
        </div>

        <div v-if="orders.length>0">
          <div v-for="(order,i) in orders" :key="order.id">
            <p class="hrHead ">
              <span  class="title">{{ i + 1 }}. 投放品牌：</span>{{ order.brandName }}<span  class="title">， 投放档期：</span>{{
                order.startDate
              }}
              至 {{ order.endDate }}， (备注：{{ order.remark || "未填写备注" }})

              <a class="m-l-10" @click="handleShowOrder(order.id)">查看订单详情</a>
            </p>

            <Row  class="table-title">
              <i-col span="3" class="text-center p-t-5">发布档期</i-col>
              <i-col span="21">
                <Row class="table-title">
                  <i-col span="4">产品名称</i-col>
                  <i-col span="2">采购数量</i-col>
                  <i-col span="5">媒体费刊例</i-col>
                  <i-col span="5">执行价</i-col>
                  <i-col span="4">服务费刊例</i-col>
                  <i-col span="4">服务费签约价</i-col>
                </Row>
              </i-col>
            </Row>
            <priceDetail
              v-for="item in order.category"
              :showDisCount="showDisCount"
              :showActualPrice="!contract.notSetPrice"
              :key="item.categoryId"
              :schedules="item.schedules"
            ></priceDetail>
          </div>
        </div>

      <OrderDetail ref="orderDetail" />

      <Modal v-model="modalContract" :width="1200" footer-hide>
        <contract-detail v-if="modalContract" :id="contractId"></contract-detail>
      </Modal>
    </div> -->

    <transactionHistory v-if="contractId" :contractId="contractId"></transactionHistory>
</template>

<script>
// import OrderDetail from '@/components/order/common/ModalOrderDetail'
// import PriceDetail from '@/components/contract/contractDetail/detail/PriceDetail'
// import ContractDetail from '@/components/contract/contractDetail/Contract'

import transactionHistory from '../../platform_settlement/settlement/common/transactionHistory'

import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
import { buildCategories } from '@/utils/orderUtils'

import { checkContractDetail } from '@/api/scp/contract'
import { listExecuteDetails } from '@/api/dw/verify'
import { getOrderProductList, getOrderScheduleCategoryList } from '@/api/scp/contractorder'
import { getOrderList } from '@/api/order/order'
import { confirmfeeitems } from '@/api/statement/bill'

// const MAX_CELL_ROW = 2

export default {
  components: { transactionHistory },
  data () {
    return {
      // modalContract: false,
      // contract: {},
      // executeData: [], // 结算内容
      // executeSummary: {
      //   sigUseTotal: 0,
      //   produceTotal: 0,
      //   installTotal: 0
      // },

      // products: [],
      // orderCount: 0,
      // orders: [],
      // productColumns: [
      //   { title: '资源名称', key: 'goodName' },
      //   {
      //     title: '采购数量',
      //     render: (h, data) => {
      //       const types = []
      //       data.row.scheduleQuantityList.forEach((item) => {
      //         types.push(
      //           h('p', [
      //             h('span', GetCurrentSchedule(item.startDate, item.endDate)),
      //             h(
      //               'span',
      //               { class: 'text-orange' },
      //               '(' +
      //                 item.signNormalQuantity +
      //                 ')'
      //             )
      //           ])
      //         )
      //       })

      //       let showMore
      //       const tooMany =
      //         data.row.scheduleQuantityList.length - MAX_CELL_ROW > 1
      //       const simple = tooMany
      //         ? types.slice(0, MAX_CELL_ROW)
      //         : types.slice(0)

      //       if (tooMany) {
      //         showMore = h(
      //           'a',
      //           {
      //             on: {
      //               click: () => {
      //                 data.row.showAll = !data.row.showAll
      //               }
      //             }
      //           },
      //           [
      //             h('Icon', {
      //               props: {
      //                 type: data.row.showAll
      //                   ? 'ios-arrow-down'
      //                   : 'ios-arrow-up',
      //                 color: '#4285f4',
      //                 size: 'large'
      //               }
      //             }),
      //             h(
      //               'span',
      //               { style: { color: '#4285f4' } },
      //               data.row.showAll ? '显示更多' : '隐藏'
      //             )
      //           ]
      //         )
      //       }
      //       simple.push(showMore)
      //       types.push(showMore)
      //       return h('div', data.row.showAll ? simple : types)
      //     }
      //   },
      //   {
      //     title: '媒体费刊例',
      //     key: 'normalUsePrice',
      //     render: (h, data) => {
      //       return h('div', [
      //         h('span', { class: 'money' }, this.formatMoney(data.row.normalUsePrice)),
      //         h(
      //           'span',
      //           '(' + data.row.usePriceDiscount + ')'
      //         )
      //       ])
      //     }
      //   },
      //   {
      //     title: '媒体发布费',
      //     key: 'signNormalPrice',
      //     render: (h, data) => {
      //       let disCountText
      //       if (this.showDisCount) {
      //         disCountText = h(
      //           'span',
      //           { class: 'discount' },
      //           this.computeShowDiscount(data.row.normalUsePrice, data.row.signNormalPrice)
      //         )
      //       }
      //       let warning
      //       // 公益、二类商业都不显示这条消息。   其余的验证是否低于最低限价
      //       if (data.row.lowerSettlementPrice) {
      //         warning = h(
      //           'p',
      //           {
      //             style: {
      //               color: '#ef4f4f',
      //               'font-size': 'small'
      //             }
      //           },
      //           '低于最低折扣'
      //         )
      //       }
      //       return h('div', [
      //         h(
      //           'span', { class: 'money' },
      //           this.formatMoney(data.row.signNormalPrice)
      //         ),
      //         disCountText,
      //         warning
      //       ])
      //     }
      //   },
      //   {
      //     title: '服务费签约价',
      //     key: 'signServicePrice',
      //     render: (h, data) => {
      //       return h('div', [
      //         h(
      //           'span', { class: 'money' },
      //           this.formatMoney(data.row.signServicePrice)
      //         ),
      //         h(
      //           'span',
      //           { class: 'discount' },
      //           this.computeShowDiscount(
      //             data.row.totalServicePrice,
      //             data.row.signServicePrice
      //           )
      //         )
      //       ])
      //     }
      //   },

      //   {
      //     title: '小计',
      //     key: 'subtotalPrice',
      //     render: (h, data) => {
      //       return h(
      //         'span', { class: 'money' },
      //         this.formatMoney(data.row.subtotalPrice)
      //       )
      //     }
      //   }
      // ]
    }
  },
  created () {
    // this.getDetail()
    // this.getBillDetail()
  },
  methods: {
    getDetail () {
      checkContractDetail({ contractId: this.contractId }).then((res) => {
        this.contract = res

        getOrderProductList({ contractId: this.contractId, version: this.contract.versionId }).then((orderProducts) => {
          this.products = []
          let lowerSettlementPrice = false
          orderProducts.forEach((element) => {
            // 计算各项汇总值
            element.subtotalPrice =
              element.signNormalPrice + element.signServicePrice

            // 公益、二类商业都不显示这条消息。   其余的验证是否低于最低限价
            lowerSettlementPrice =
              this.showDisCount &&
              element.normalSellPrice + element.recommendSellPrice <
                element.settlementPrice &&
              this.contract.contractCategory !== 2

            this.products.push(
              Object.assign({}, element, {
                lowerSettlementPrice: lowerSettlementPrice,
                showAll: true
              })
            )
          })
        })

        this.getContractOrder()
      })
    },
    getContractOrder () {
      const query = {
        contractId: this.contractId, version: this.contract.versionId
      }

      getOrderScheduleCategoryList(query).then(res => {
        this.orderCount = res.length
        const orderIds = res.map((o) => o.orderId)
        if (res.length) {
          getOrderList({ orderIdStr: JSON.stringify(orderIds) }).then((orderDetails) => {
            this.orders = orderDetails.map((order) => {
              const priceSetting = res.find(
                (od) => od.orderId === order.id
              )
              order.category = buildCategories(
                priceSetting.signPriceDetail
              )
              return order
            })
          })
        }
      })
    },
    getBillDetail () {
      const query = {
        contractId: this.contractId,
        startDate: this.transactionReconciliationQuery.startDate,
        endDate: this.transactionReconciliationQuery.endDate
      }

      listExecuteDetails(query).then(res => {
        this.executeData = res
        this.executeSummary = {
          sigUseTotal: 0,
          produceTotal: 0,
          installTotal: 0
        }
        res.forEach(element => {
          this.executeSummary.sigUseTotal += element.signUsePrice
          this.executeSummary.produceTotal += element.producePrice
          this.executeSummary.installTotal += element.installPrice
        })
      })
    },
    handleSummary ({ columns, data }) {
      let needSummarykey = [
        'usePrice'
      ]
      if (!this.contract.notSetPrice) {
        needSummarykey = needSummarykey.concat(['publishPirce',
          'servicePrice',
          'otherPrice',
          'subtotalPrice'])
      }
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatSchedule (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },

    handleShowOrder (orderId) {
      // 根据菜单动态跳转链接
      this.$refs.orderDetail.init(orderId)
    },
    computeShowDiscount (denominator, member) {
      if (denominator > 0) {
        const discount = (member / denominator) * 10

        // toFixed()方法, 四舍五入
        const showDisCount = discount.toFixed(2)
        // Math.floor()，不四舍五入 ，向下取整
        // const showDisCount = Math.floor(discount * 100) / 100

        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
    },
    handleConfirmRecondiciliation () {
      this.$Modal.confirm({
        title: '操作确认',
        content: '<p>是否要将对账项标记为已对账，确认后<span style="color:red;font-weight:600;">无法再次变更</span>，请谨慎操作！</p>',
        loading: true,
        onOk: () => {
          const query = {
            feeitemListJsonStr: JSON.stringify([this.contractId]),
            startDate: this.transactionReconciliationQuery.startDate,
            endDate: this.transactionReconciliationQuery.endDate,
            feeitemType: 1
          }

          confirmfeeitems(query).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '成功提交对账！' })
              // 刷新页面数据， 并跳转至列表页面
              this.$store.commit('set_reconciliationDemo_pageType', 'List')
              this.$store.commit('set_reconciliationDemo_reloadList', new Date())
            }
            this.$Modal.remove()
          }).catch(() => {
            this.$Modal.remove()
          })
        }
      })
    }
  },
  computed: {
    detailBean () {
      return this.$store.state.reconciliationDemo.detailBean
    },
    contractId () {
      return this.detailBean.contractId
    },
    executeStatus () {
      return this.detailBean.confirmStatus
    },
    exeFlag () {
      return this.detailBean.exeFlag
    },
    transactionReconciliationQuery () {
      return this.$store.state.reconciliationDemo.transactionReconciliationQuery
    },
    showDisCount () {
      return !this.contract.public
    }
  },
  watch: {
    contractId () {
      // this.getDetail()
      // this.getBillDetail()
    }
  }
}
</script>
